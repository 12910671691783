<template>
  <div class="login-box-wrap">
    <div class="white-block login-box">
      <h1>{{ t("login_page.login") }}</h1>
      <Login />
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

import Login from "../components/Login.vue";

export default {
  name: "LoginPage",
  components: {
    Login,
  },
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
};
</script>

<style scoped>
.login-box-wrap {
  height: 80vh;
  padding-top: 20px;
}

.login-box h1 {
  text-align: start;
  font-size: 20px;
  margin-bottom: 30px;
  margin-top: 0;
}

.login-box {
  margin: auto;
  max-width: min(90vw, 600px);
  padding: 40px;
}
</style>
